.App {
  text-align: center;
}



.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

*{
  box-sizing: border-box;
}

.App_calculation{
 
  font-family: sans-serif;
  text-align: center;
  display: flex;
  flex-direction: column;
  margin: 10px auto 10px auto;
  /* width:400px; */
  /* background: #3A4655; */
  /* background: #E2E2ED; */
  
  min-height: 400px;
  /* box-shadow: 0 8px 10px -7px gray; */
}


.show-calculation{
  display:flex;
  margin: 20px 25px;
  color:grey;
  flex-direction: column;
}

.result-calculation{
  font-size:24px;
  flex:1;
  display: flex;
  justify-content: right;

 
}
@media (max-width: 768px) {
  .result-calculation{
    font-size: 17Fpx; /* Change the padding to 5px for smaller screens */
  }
}

/* Media query for screens with a width greater than or equal to 768px */


.dashed-line{
  flex:1;
  margin-top:10px;
  border-bottom: 1px dashed grey;
}

.final-result{
  font-size:30px;
  flex:1;
  margin: 15px 25px 10px 0px;
  display: flex;
  color:white;
  justify-content: right;
}

.button-layout{
  display:flex;
  justify-content: space-around;
  flex-wrap: wrap;
}

/* .button-style {
  flex-basis: 20%;
  margin: 0px 10px 0 10px ;
  background: #4B527E;
  font-size: 25px;
  text-align: center;
  border: none;
  border-right: solid 2px #354259;
  border-bottom: solid 2px #354259;
  color: white;
}


@media (max-width: 768px) {
  .button-style {
    padding: 5px; 
    flex-basis: 15%;

  }
} */


.btn-square-md {
  width: 100px !important;
  max-width: 100% !important;
  max-height: 100% !important;
  height: 100px !important;
  text-align: center;
  padding: 0px;
  font-size:12px;
  }
  @media (max-width: 768px) {
    .btn-square-md {
      width: 83px !important;
      height: 85px !important; /* Change the padding to 5px for smaller screens */
    }
  
    .display_montant {
      font-weight: 600 !important;
      /* font-size: 20px !important; */
      /* padding-bottom: 20px; */
    }

  }
  
  .display_montant {
    font-weight: 600;
    font-size: 20px;

  }
  
  /* @media (min-width: 768px) {
    .btn-square-md {
      width: 80px ;
      height: 85px;  
    }
  } */


  .bottom-sheet-modal {
    position: fixed;
    left: 0;
    bottom: 0;
    z-index: 1050;
    width: 100%;
    height: auto;
    margin: 0;
    padding: 1rem;
    border: none;
    border-radius: 0;
    background-color: #fff;
    transform: translate3d(0, 100%, 0);
    transition: transform 0.3s ease-in-out;
  }
  
  .bottom-sheet-modal.show {
    transform: translate3d(0, 0, 0);
  }

  /* .modal-dialog {
    max-width: none !important;
  } */

  .custom-modal .modal-dialog {
    max-width: none  !important;
  }

  .product-list-block_ {
    display: flex;
    flex-wrap: wrap;
    gap: 5px; /* Adjust the gap size as needed */
    /* min-height: calc(100vh - 2400px); */
  }
  
  .product-list-block__product {
    flex: 0 0 auto;
  }

 




