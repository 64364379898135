@import "../components/variables";

.print-data {
    table {
        tr {
            td {
                border: 0;
                color: $gray-900;
                font-size: 1rem;
                padding-right: 0;
                padding-left: 0;
                padding-bottom: 0;
            }
        }
    }
}

